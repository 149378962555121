import { axios, Axios } from '@bundled-es-modules/axios';
import { getaccessToken } from './utils';

class UploadFunctionsClient {
  private client: Axios | null = null;

  public initComplete: Promise<void>;

  public accessToken: string | null | undefined = null;

  constructor() {
    this.initComplete = this._setAxiosInstance();
  }

  async _setAxiosInstance() {
    this.accessToken = await getaccessToken();
    this.client = axios.create({
      baseURL: 'https://northstar-staging-app-fastapi.azurewebsites.net/',
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
  }

  async post(path, body) {
    await this.initComplete;

    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }

    // this.client.defaults.headers.common['Sec-Fetch-Mode'] = 'cors';
    // this.client.defaults.headers.common['Sec-Fetch-Site'] = 'cross-site';
    const response = await this.client.post(path, body);

    if (response.data?.data) {
      return response.data?.data;
    }

    return response.data;
  }

  async get(path) {
    await this.initComplete;

    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }

    const response = await this.client.get(path);

    if (response.data?.data) {
      return response.data?.data;
    }

    return response.data;
  }

  uploadFiles(formData) {
    return this.post('/adele/upload', formData);
  }

  vanillaUploadFiles(formData) {
    return this.post('/adele/vanilla-upload', formData);
  }
}

export { UploadFunctionsClient };
