import { property } from 'lit/decorators';
import { css, CSSResultGroup, html, LitElement } from 'lit';

import '@vaadin/text-field';
import '@vaadin/button';

import { vaadinStyles } from '../../assets/styles/vaadinStyles';

export class LoginForm extends LitElement {
  @property({ type: String }) _email: string = '';

  static styles?: CSSResultGroup | undefined = [
    ...vaadinStyles,
    css`
      * {
        box-sizing: border-box;
      }

      .container {
        height: 300px;
        width: 700px;
        background-color: #00000080;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 14px;
        padding: 3rem;
      }

      .wrapper {
        width: 100%;
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
        align-items: flex-end;
      }

      vaadin-text-field {
        width: 100%;
      }

      vaadin-text-field > input {
        font-size: 24px;
      }

      vaadin-button {
        color: white;
        background-color: #f82c91;
        width: fit-content;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:focus {
        transition: background-color 0s 600000s, color 0s 600000s !important;
      }
    `,
  ];

  _login() {
    this.dispatchEvent(
      new CustomEvent('login', { detail: { email: this._email } })
    );
  }

  _valueChanged(e) {
    this._email = e.detail.value;
  }

  render() {
    return html`<div class="container">
      <div class="wrapper">
        <vaadin-text-field
          label="Log in with your email"
          @value-changed=${this._valueChanged}
        ></vaadin-text-field>
        <vaadin-button @click=${this._login}>Login</vaadin-button>
      </div>
    </div>`;
  }
}
