import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AppConfig } from '../../types';

interface User {
  id: number;
  name: string;
}

// Define a type for the slice state
interface AppConfigState {
  value: AppConfig | undefined;
  allowedSources: any | undefined;
  allowedAgents: any | undefined;
  selectedSource: any | null;
  selectedAgent: any | null;
  accounts: Array<User> | undefined;
  currentAccount: User | null;
  agreedToTerms: boolean;
  defaultSource: number | null;
  canUpload: boolean;
  isAdmin: boolean;
  products: Array<any> | undefined;
  vanilla: boolean;
}

// Define the initial state using that type
const initialState: AppConfigState = {
  value: undefined,
  allowedSources: undefined,
  allowedAgents: undefined,
  selectedSource: null,
  selectedAgent: null,
  accounts: undefined,
  currentAccount: null,
  agreedToTerms: false,
  defaultSource: null,
  canUpload: false,
  isAdmin: false,
  products: undefined,
  vanilla: false,
};

const appConfigSlice = createSlice({
  name: 'appConfig',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setAppConfig: (state, action: PayloadAction<AppConfig>) => {
      state.value = action.payload;
    },
    setAllowedSouces: (state, action: PayloadAction<any>) => {
      state.allowedSources = action.payload;
      state.selectedSource = null;
    },
    setAllowedAgents: (state, action: PayloadAction<any>) => {
      state.allowedAgents = action.payload;
      state.selectedAgent = null;
    },
    setSelectedSource: (state, action: PayloadAction<any>) => {
      state.selectedSource = action.payload;
    },
    setselectedAgent: (state, action: PayloadAction<any>) => {
      state.selectedAgent = action.payload;
    },
    setAccounts: (state, action: PayloadAction<any>) => {
      state.accounts = action.payload;
    },
    setCurrentAccount: (state, action: PayloadAction<any>) => {
      state.currentAccount = action.payload;
    },
    setAgreedToTerms: (state, action: PayloadAction<boolean>) => {
      state.agreedToTerms = action.payload;
    },
    setDefaultSource: (state, action: PayloadAction<number>) => {
      state.defaultSource = action.payload;
    },
    setAppConfigCanUpload: (state, action: PayloadAction<boolean>) => {
      state.canUpload = action.payload;
    },
    setAppConfigIsAdmin: (state, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload;
    },
    setAppConfigProducts: (state, action: PayloadAction<Array<any>>) => {
      state.products = action.payload;
    },
    setAppConfigVanilla: (state, action: PayloadAction<boolean>) => {
      state.vanilla = action.payload;
    },
  },
});

const {
  setAppConfig,
  setAllowedSouces,
  setAllowedAgents,
  setSelectedSource,
  setselectedAgent,
  setAccounts,
  setCurrentAccount,
  setAgreedToTerms,
  setDefaultSource,
  setAppConfigCanUpload,
  setAppConfigIsAdmin,
  setAppConfigProducts,
  setAppConfigVanilla,
} = appConfigSlice.actions;
const appConfigReducer = appConfigSlice.reducer;

export {
  AppConfigState,
  appConfigReducer,
  setAppConfig,
  setAllowedSouces,
  setAllowedAgents,
  setSelectedSource,
  setselectedAgent,
  setAccounts,
  setCurrentAccount,
  setAgreedToTerms,
  setDefaultSource,
  setAppConfigCanUpload,
  setAppConfigIsAdmin,
  setAppConfigProducts,
  setAppConfigVanilla,
};
