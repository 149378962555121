import { FunctionsClient } from './Functions';
import { AuthFunctionsClient } from './AuthFunctions';
import { UploadFunctionsClient } from './UploadFunctions';
import { ModelFunctionsClient } from './ModelFunctions';
import { AdeleClient } from './Adele';

const functionsClient = new FunctionsClient();
const authFunctionsClient = new AuthFunctionsClient();
const modelFunctionsClient = new ModelFunctionsClient();
const uploadFunctionsClient = new UploadFunctionsClient();
const adeleClient = new AdeleClient();

export {
  modelFunctionsClient,
  functionsClient,
  authFunctionsClient,
  uploadFunctionsClient,
  adeleClient,
  AuthFunctionsClient,
  FunctionsClient,
  ModelFunctionsClient,
  UploadFunctionsClient,
  AdeleClient,
};
