const ENVIROMENTS = {
  PRODUCTION: 'PRODUCTION',
  DEV: 'DEV',
  STAGING: 'STAGING',
};

function getEnv() {
  console.log(window.location.hostname);

  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname.includes('dev')
  ) {
    console.log('current ENV is DEV');
    return ENVIROMENTS.DEV;
  }
  if (window.location.hostname.includes('staging')) {
    console.log('current ENV is STAGING');
    return ENVIROMENTS.STAGING;
  }
  console.log('current ENV is PRODUCTION');
  return ENVIROMENTS.PRODUCTION;
}

console.log(`Selected Environment: ${getEnv()}`);

const CURRENT_ENV = {
  ENVIRONMENT: getEnv(),
};

export { CURRENT_ENV, ENVIROMENTS };
