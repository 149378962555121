import { property } from 'lit/decorators';
import { LitElement, html } from 'lit';
import { Notification } from '@vaadin/notification';
import { connect, store } from '../../../state/store';

import '@mch/nn-web-viz/dist/nn-star-rating';

import { xmark } from '../../../assets/icons/xmark';

import { monday, mondayQueries } from '../../../modules/monday/data';
import { AdeleHelpOverlayStyles } from './AdeleHelpOverlayStyles';

const getCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

class AdeleHelpOverlay extends connect(store)(LitElement) {
  @property({ type: String, attribute: 'question' }) question = '';

  @property({ type: String, attribute: 'answer' }) answer = '';

  @property({ type: Number, attribute: 'thread-id' }) threadId = 0;

  @property({ type: Boolean }) _sendingFeedback = false;

  @property({ type: Object }) user;

  static styles = [...AdeleHelpOverlayStyles];

  stateChanged(_state) {
    this.user = _state.user.value;
  }

  _closeOverlay() {
    this.dispatchEvent(new Event('close-overlay', { bubbles: true }));
  }

  _handleSubmit(e) {
    e.preventDefault();

    const inputField = this.shadowRoot?.getElementById(
      'search'
    ) as HTMLInputElement;
    inputField.value = '';
  }

  async _submitHelpRequest() {
    const inputField = this.shadowRoot?.getElementById(
      'search'
    ) as HTMLInputElement;
    const helpRequestText = inputField.value;
    const userName = this.user.nickname || 'Username'; // Replace with actual user name retrieval logic

    this._sendingFeedback = true;
    try {
      const result = await monday.query(
        mondayQueries.submitHelpRequest({
          text: helpRequestText.replace(/(\r\n|\n|\r)/gm, ''),
          userName,
          date: getCurrentDate(),
        })
      );

      if (result?.create_item) {
        inputField.value = '';
        Notification.show('Your help request has been submitted.', {
          position: 'bottom-end',
          duration: 2000,
          theme: 'success',
        });
        this._closeOverlay();
      } else {
        Notification.show('Failed to create item.', {
          position: 'bottom-end',
          duration: 5000,
          theme: 'error',
        });
      }
    } catch (error) {
      Notification.show('Unable to submit help request.', {
        position: 'bottom-end',
        duration: 5000,
        theme: 'error',
      });
    }
    this._sendingFeedback = false;
  }

  _renderSearchInput() {
    return html`<form class="search" @submit=${e => this._handleSubmit(e)}>
      <div>
        <h3>Need Help?</h3>
        <p>
          If you need assistance, you can email us at
          <a href="mailto:help@90north.health">help@90north.health</a> or fill
          out the form below:
        </p>
      </div>
      <div class="input__label">Describe your issue or request</div>

      <div class="search__input-wrap">
        <textarea
          class="search__input"
          id="search"
          type="text"
          name="search"
          placeholder="Type in here..."
        ></textarea>
      </div>
      <div class="submit__wrapper">
        <nn-button
          class="submit__btn"
          ghost
          @click=${this._submitHelpRequest}
          ?disabled=${this._sendingFeedback}
          >Submit Help Request</nn-button
        >
      </div>
    </form>`;
  }

  render() {
    return html`<div class="wrapper">
      <div class="header-actions">
        <nn-icon @click=${this._closeOverlay} .svg=${xmark}></nn-icon>
      </div>
      <div class="content">${this._renderSearchInput()}</div>
    </div>`;
  }
}

export { AdeleHelpOverlay };
