import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
interface ClaimsState {
  items: Array<any> | undefined | any;
}

// Define the initial state using that type
const initialState: ClaimsState = {
  items: undefined,
};

const claimsSlice = createSlice({
  name: 'claims',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setClaims: (state, action: PayloadAction<any>) => {
      state.items = action.payload;
    },
  },
});

const { setClaims } = claimsSlice.actions;
const claimsReducer = claimsSlice.reducer;

export { ClaimsState, claimsReducer, setClaims };
