import { axios, Axios } from '@bundled-es-modules/axios';
import { MONGO_ENDPOINT } from '../../constants';
import { getaccessToken } from './utils';

class MongoFunctionsClient {
  private client: Axios | null = null;

  public accessToken: string | null | undefined = null;

  public initComplete: Promise<void>;

  constructor() {
    this.initComplete = this._setAxiosInstance();
  }

  async _setAxiosInstance() {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    this.accessToken = await getaccessToken();
    this.client = axios.create({
      baseURL: MONGO_ENDPOINT,
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        ContentType: 'application/json',
      },
    });
  }

  async get(path) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    const response = await this.client.get(path);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }

  async post(path, body) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    const response = await this.client.post(path, body);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }

  async getMessagesByThreadId(threadId) {
    return this.get(
      `/messages/${threadId}?sort_field=thread_id&sort_order=1`
    );
  }

  async getAllMessages() {
    return this.get(`/messages`);
  }

  async postMessages({
    question,
    answer,
    sourceId,
    agentId,
    recommendations,
    citations,
    imageUrl,
    tooltip,
    step,
    attachment,
    threadId,
  }) {
    return this.post('/messages', [
      {
        thread_id: threadId,
        question,
        answer,
        image_url: imageUrl,
        created: '2025-01-20T15:23:45.123Z', // ISO 8601 Format, auto now() if this field is not set
        updated: '2025-01-20T15:23:45.123Z', // ISO 8601 Format, auto now() if this field is not set
        tooltip,
        source_id: sourceId,
        agent_id: agentId,
        step,
        attachment,
        recommendations,
        citations,
        backend_response: { status: 'success' },
      },
    ]);
  }
}
export { MongoFunctionsClient };
