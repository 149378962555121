import { axios, Axios } from '@bundled-es-modules/axios';
import { ADELE_ENDPOINT } from '../../constants';
import { getaccessToken } from './utils';

class AdeleClient {
  private client: Axios | null = null;

  public initComplete: Promise<void>;

  public accessToken: string | null | undefined = null;

  constructor() {
    this.initComplete = this._setAxiosInstance();
  }

  async _setAxiosInstance() {
    this.accessToken = await getaccessToken();
    this.client = axios.create({
      baseURL: ADELE_ENDPOINT,
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        contentType: 'application/zip',
      },
      responseType: 'blob',
    });
  }

  async post(path, body) {
    await this.initComplete;

    if (this.client == null) {
      throw new Error('Adele client is not initialized');
    }

    const response = await this.client.post(path, body);

    if (response.data?.data) {
      return response.data?.data;
    }

    return response.data;
  }

  async get(path) {
    await this.initComplete;

    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }

    const response = await this.client.get(path, { reesponseType: 'blob' });

    if (response.data?.data) {
      return response.data?.data;
    }

    return response.data;
  }

  // eslint-disable-next-line camelcase
  getTable(payload: { thread_id: any; message_id: any }) {
    return this.get(
      `/get-table?thread_id=${payload.thread_id}&message_id=${payload.message_id}`
    );
  }
}

export { AdeleClient };
