/* eslint-disable class-methods-use-this */
import { mongoFunctionsClient } from '../modules/functions/client';
import { hasura, hasuraQueries } from '../modules/hasura';

export default class ThreadService {
  public async createThreadId(name: string) {
    const result = await hasura.query(hasuraQueries.createThreadId(name));

    return result?.insert_thread?.returning[0]?.id || null;
  }

  public async addMessageToThread({ question, answer, source, agent, thread }) {
    const result = await hasura.query(hasuraQueries.insertMessageToThread(), {
      objects: [
        {
          question,
          answer,
          source_id: source,
          agent_id: agent,
          recommendations: JSON.stringify(
            thread.messages[thread.messages.length - 1].recommendations
          ),
          citations: JSON.stringify(
            thread.messages[thread.messages.length - 1].citations
          ),
          image_url: JSON.stringify(
            thread.messages[thread.messages.length - 1].image_url
          ),
          tooltip: JSON.stringify(
            thread.messages[thread.messages.length - 1].tooltip
          ),
          step: JSON.stringify(
            thread.messages[thread.messages.length - 1].step
          ),
          thread_id: thread.id,
        },
      ],
    });

    return result?.insert_message?.returning[0] || null;
  }

  public async createThread(
    { question, answer, source, agent, thread },
    id = null
  ) {
    let threadId = id;

    if (id == null) {
      threadId = await this.createThreadId(question);
    }

    const result = await hasura.query(hasuraQueries.createThread(), {
      objects: [
        {
          thread_id: threadId,
          question,
          answer,
          source_id: source,
          agent_id: agent,
          recommendations: JSON.stringify(thread.messages[0].recommendations),
          citations: JSON.stringify(thread.messages[0].citations),
          image_url: JSON.stringify(thread.messages[0].image_url),
          tooltip: JSON.stringify(thread.messages[0].tooltip),
          step: JSON.stringify(thread.messages[0].step),
        },
      ],
    });

    return result?.insert_message?.returning?.[0] || null;
  }

  public async getAllThreads() {
    const result = await hasura.query(hasuraQueries.getThreads());

    return result?.thread || [];
  }

  public async getSortedThreads() {
    const threads = await this.getAllThreads();

    return this.sortThreads(threads);
  }

  public async getThreadById(threadId: string) {
    const result = await mongoFunctionsClient.getMessagesByThreadId(threadId);

    return result;
  }

  public sortThreads(threads) {
    return threads.sort((a, b) => a.id - b.id);
  }
}
