import {
  AzureConfiguration,
  azureConfigurations,
  AzureConfigurations,
  AzureScope,
} from './azure-configurations';
import {
  CUBE_ENDPOINT,
  HASURA_ENDPOINT,
  MONDAY_ENDPOINT,
  FUNCTIONS_ENDPOINT,
  MODELS_ENDPOINT,
  AUTH_FUNCTIONS_ENDPOINT,
  CHAT_BASE_URL,
  SEARCH_ENDPOINT,
  AUTH0_CONFIG,
  WEBSOCKET_ENDPOINT,
  ADELE_ENDPOINT,
} from './endpoints';

export {
  CUBE_ENDPOINT,
  HASURA_ENDPOINT,
  MONDAY_ENDPOINT,
  FUNCTIONS_ENDPOINT,
  MODELS_ENDPOINT,
  AUTH_FUNCTIONS_ENDPOINT,
  CHAT_BASE_URL,
  SEARCH_ENDPOINT,
  AUTH0_CONFIG,
  WEBSOCKET_ENDPOINT,
  ADELE_ENDPOINT,
  AzureConfiguration,
  AzureConfigurations,
  AzureScope,
  azureConfigurations,
};
