import { sendEvent } from '@mch/nn-web-viz/dist/packages/analytics';
import { css, html, LitElement } from 'lit';
import { property } from 'lit/decorators';

const pasteEvent = e => {
  e.preventDefault();

  sendEvent(e, {
    id: 'adele-chat-input__paste-event',
    description: 'Paste text into the chat input',
  });
  const text = e.clipboardData.getData('text');
  document.execCommand('insertText', false, text);
};

export class AdeleSearchInput extends LitElement {
  @property({ type: String }) modelValue: string = '';

  static styles: any = [
    css`
      :host {
        box-sizing: border-box;
        width: 100%;
      }

      .search__contenteditable {
        display: block;
        font-size: 1rem;
        width: 100%;
        color: white;
        word-break: break-word;
      }

      .search__contenteditable:focus {
        outline: none;
      }

      [contenteditable='true']:empty:not(:focus):before {
        content: 'Ask a question';
        pointer-events: none;
        -webkit-user-modify: read-write-plaintext-only;
      }
    `,
  ];

  connectedCallback(): void {
    super.connectedCallback();

    this.addEventListener('paste', pasteEvent);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();

    this.removeEventListener('paste', pasteEvent);
  }

  updated(changedProps) {
    super.updated(changedProps);

    if (changedProps.has('modelValue')) {
      const editableElement: HTMLElement = this.shadowRoot?.querySelector(
        '.search__contenteditable'
      ) as HTMLElement;

      if (editableElement != null) {
        if (editableElement.innerText === '') {
          editableElement.innerText = this.modelValue;
          this.dispatchEvent(
            new CustomEvent('model-value-changed', { detail: this.modelValue })
          );
        }
      }
    }
  }

  clear() {
    this.modelValue = '';

    const editableElement: HTMLElement = this.shadowRoot?.querySelector(
      '.search__contenteditable'
    ) as HTMLElement;

    if (editableElement != null) {
      editableElement.innerText = '';
      editableElement.focus();
    }
  }

  _keypressChanged(e: KeyboardEvent) {
    if (e.shiftKey && e.key === 'Enter') {
      return;
    }

    if (e.key === 'Enter') {
      e.preventDefault();

      this.dispatchEvent(
        new CustomEvent('search', { detail: this.modelValue })
      );
      this.clear();
    }
  }

  _inputChanged() {
    const editableTextElement: HTMLElement = this.shadowRoot?.querySelector(
      '.search__contenteditable'
    ) as HTMLElement;

    if (editableTextElement == null) {
      return;
    }

    this.modelValue = editableTextElement.innerText;
    this.dispatchEvent(
      new CustomEvent('model-value-changed', { detail: this.modelValue })
    );
  }

  render() {
    return html`
      <div
        contenteditable="true"
        class="search__contenteditable"
        @keypress=${this._keypressChanged}
        @input=${this._inputChanged}
        tabindex="0"
      ></div>
    `;
  }
}
