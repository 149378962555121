import { axios, Axios } from '@bundled-es-modules/axios';
import { getHasuraToken } from '../hasura/client';
import { FUNCTIONS_ENDPOINT } from '../../constants';
import { SetThemeFunctionParams } from './types/SetThemeFunctionParam';
import { SetThemeSummariesBody } from './types/SetThemeSummariesBody';

class FunctionsClient {
  private client: Axios | null = null;

  public hasuraToken: string | null = null;

  public initComplete: Promise<void>;

  constructor() {
    this.initComplete = this._setAxiosInstance();
  }

  async _setAxiosInstance() {
    this.hasuraToken = await getHasuraToken();
    this.client = axios.create({
      baseURL: FUNCTIONS_ENDPOINT,
    });
  }

  async setThemeSummaries({
    subThemeId,
    macroThemeId,
  }: SetThemeFunctionParams) {
    const body: SetThemeSummariesBody = {
      hasura_token: this.hasuraToken,
    };

    if (subThemeId != null) {
      body.sub_theme_id = subThemeId;
    }

    if (macroThemeId != null) {
      body.macro_theme_id = macroThemeId;
    }

    return this.post('/set-theme-summaries', body);
  }

  executeMessageOptimizer(behavioralOption, userInput) {
    const body = {
      chatToken: this.hasuraToken,
      behavioral_option: behavioralOption,
      user_input: userInput,
    };

    return this.post('/message-optimizer/generate', body);
  }

  async post(path, body) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    const response = await this.client.post(path, body);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }
}

export { FunctionsClient };
