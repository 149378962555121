import { css } from 'lit';

const AdeleNewThreadOverlayStyles = css`
  * {
    box-sizing: border-box;
  }

  :host {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
  }

  :host::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(14px);
  }

  .header-actions {
    display: flex;
    justify-content: flex-end;
    padding: 2rem 2rem 0 0;
  }

  nn-icon {
    cursor: pointer;
  }

  vaadin-text-field {
    color: white;
  }

  .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .search {
    margin: auto;
    width: 100%;
    max-width: 1136px;
    border-radius: 30px;
    border: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 16px;
  }

  .search__icon {
    display: block;
    margin: auto;
    width: 35px;
    height: 35px;
  }
  .search__button:focus-visible .search__icon,
  .search__button:hover .search__icon {
    color: #fff;
  }
  .search__icon-g {
    transform: translate(6px, 6px);
    transform-origin: 12px 12px;
  }
  .search__icon-line2,
  .search__icon-line3 {
    transform-origin: 21px 21px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 0px solid #434346;
    -webkit-text-fill-color: #f1f1f1;
    -webkit-box-shadow: 0 0 0px 1000px rgba(48, 52, 64, 0.1) inset;
    transition: background-color 5000s ease-in-out 0s;
    caret-color: white;
  }

  .search__label {
    position: absolute;
    width: 1px;
    height: 1px;
  }
  .search__upload {
    height: 100%;
    display: inline-block;
    cursor: pointer;
  }

  .search__send {
    height: 100%;
    display: inline-block;
    cursor: pointer;
  }

  .search__actions {
    justify-content: space-between;
    align-items: center;
    display: flex;
    position: absolute;
    right: 30px;
    height: 100%;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
  }

  .search button {
    display: block;
    height: 100%;
    background-color: transparent;
    border: 0;
    outline: transparent;
    cursor: pointer;
    z-index: 1;
    margin: 0;
    margin-right: 1rem;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
  }

  .search__input-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(18px);
    box-shadow: 0px 24px 30px 0px rgba(28, 31, 40, 0.1);
    border-radius: 30px;
    padding: 1rem 2rem;
    margin: 0 5rem;
  }

  .search-wrapper {
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
  }
`;

export { AdeleNewThreadOverlayStyles };
