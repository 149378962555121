import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UserQuery } from '../../types';

// Define a type for the slice state
interface UserQueryState {
  value: UserQuery | null | undefined;
}

// Define the initial state using that type
const initialState: UserQueryState = {
  value: undefined,
};

const userQuerySlice = createSlice({
  name: 'userQuery',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setUserQuery: (state, action: PayloadAction<UserQuery>) => {
      state.value = action.payload;
    },
    clearUserQuery: state => {
      state.value = null;
    },
  },
});

const { setUserQuery, clearUserQuery } = userQuerySlice.actions;
const userQueryReducer = userQuerySlice.reducer;

export { UserQueryState, userQueryReducer, setUserQuery, clearUserQuery };
