import { axios, Axios } from '@bundled-es-modules/axios';
import { MODELS_ENDPOINT } from "../../constants";
import { getaccessToken } from "./utils";

class ModelFunctionsClient {
  private client: Axios | null = null;

  public initComplete: Promise<void>;

  public accessToken: string | null | undefined = null;

  constructor() {
    this.initComplete = this._setAxiosInstance();
  }

  async _setAxiosInstance() {
    this.accessToken = await getaccessToken();
    this.client = axios.create({
      baseURL: MODELS_ENDPOINT,
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
  }

  async post(path, body) {
    await this.initComplete;

    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }

    const response = await this.client.post(path, body);

    if (response.data?.data) {
      return response.data?.data;
    }

    return response.data;
  }

  async get(path) {
    await this.initComplete;

    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }

    const response = await this.client.get(path);

    if (response.data?.data) {
      return response.data?.data;
    }

    return response.data;
  }

  getChatModels(chatToken) {
    const body = {
      chatToken,
    };

    return this.post('/hate-audit-models', body);
  }

  async getCitation(refId) {
    return { [refId]: await this.get(`get-citation/${refId}`) };
  }
}

export { ModelFunctionsClient };
