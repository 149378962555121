import { css } from 'lit';

const vaadinStyles = [
  css`
    :host {
      --lumo-primary-text-color: var(--nn-primary-text-color);
      --lumo-primary-color: var(--nn-accent-color);

      --lumo-body-text-color: white;
      --lumo-base-color: #181b30;
      --lumo-tint-5pct: hsla(214, 65%, 85%, 0.06);
      --lumo-tint-10pct: hsla(214, 60%, 80%, 0.14);
      --lumo-tint-20pct: hsla(214, 64%, 82%, 0.23);
      --lumo-tint-30pct: hsla(214, 69%, 84%, 0.32);
      --lumo-tint-40pct: hsla(214, 73%, 86%, 0.41);
      --lumo-tint-50pct: hsla(214, 78%, 88%, 0.5);
      --lumo-tint-60pct: hsla(214, 82%, 90%, 0.58);
      --lumo-tint-70pct: hsla(214, 87%, 92%, 0.69);
      --lumo-tint-80pct: hsla(214, 91%, 94%, 0.8);
      --lumo-tint-90pct: hsla(214, 96%, 96%, 0.9);
      --lumo-tint: hsl(214, 100%, 98%);
      --lumo-shade-5pct: hsla(214, 0%, 0%, 0.07);
      --lumo-shade-10pct: hsla(214, 4%, 2%, 0.15);
      --lumo-shade-20pct: hsla(214, 8%, 4%, 0.23);
      --lumo-shade-30pct: hsla(214, 12%, 6%, 0.32);
      --lumo-shade-40pct: hsla(214, 16%, 8%, 0.41);
      --lumo-shade-50pct: hsla(214, 20%, 10%, 0.5);
      --lumo-shade-60pct: hsla(214, 24%, 12%, 0.6);
      --lumo-shade-70pct: hsla(214, 28%, 13%, 0.7);
      --lumo-shade-80pct: hsla(214, 32%, 13%, 0.8);
      --lumo-shade-90pct: hsla(214, 33%, 13%, 0.9);
      --lumo-shade: hsl(214, 33%, 13%);
      --lumo-contrast-5pct: var(--lumo-tint-5pct);
      --lumo-contrast-10pct: var(--lumo-tint-10pct);
      --lumo-contrast-20pct: var(--lumo-tint-20pct);
      --lumo-contrast-30pct: var(--lumo-tint-30pct);
      --lumo-contrast-40pct: var(--lumo-tint-40pct);
      --lumo-contrast-50pct: var(--lumo-tint-50pct);
      --lumo-contrast-60pct: var(--lumo-tint-60pct);
      --lumo-contrast-70pct: var(--lumo-tint-70pct);
      --lumo-contrast-80pct: var(--lumo-tint-80pct);
      --lumo-contrast-90pct: var(--lumo-tint-90pct);
      --lumo-contrast: var(--lumo-tint);
      --lumo-header-text-color: var(--lumo-contrast);
      --lumo-body-text-color: var(--lumo-contrast-90pct);
      --lumo-secondary-text-color: var(--lumo-contrast-70pct);
      --lumo-tertiary-text-color: var(--lumo-contrast-50pct);
      --lumo-disabled-text-color: var(--lumo-contrast-70pct);
      --lumo-primary-color-50pct: #f8029c70;
      --lumo-primary-color-10pct: #f8029c30;
      --lumo-primary-contrast-color: #fff;
      --lumo-error-color: hsl(3, 79%, 49%);
      --lumo-error-color-50pct: hsla(3, 75%, 62%, 0.5);
      --lumo-error-color-10pct: hsla(3, 75%, 62%, 0.14);
      --lumo-error-text-color: hsl(3, 100%, 80%);
      --lumo-success-color: #16ffcf;
      --lumo-success-color-50pct: #16ffcf50;
      --lumo-success-color-10pct: #16ffcf10;
      --lumo-success-text-color: #006550;
    }

    vaadin-text-field::part(input-field),
    vaadin-multi-select-combo-box::part(input-field) {
      background-color: #242848;
      border-radius: 8px;
      color: #c5c8e2;
      border: 1px solid var(--lumo-contrast-20pct);
      height: 32px;
      font-size: 16px;
      line-height: 16.37px;
      letter-spacing: -0.02em;
    }

    vaadin-text-field::part(helper-text) {
      font-size: 12px;
    }

    vaadin-select::part(input-field),
    vaadin-multi-select-combo-box::part(input-field) {
      height: 32px;
    }

    vaadin-checkbox::part(checkbox) {
      background-color: #343a68;
    }

    vaadin-checkbox[checked]::part(checkbox) {
      background-color: #f8029c;
    }

    vaadin-notification-card::part(overlay) {
      padding: 16px 19px;
      border-radius: 16px;
      background: rgba(28, 31, 40, 0.28);
      box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
      backdrop-filter: blur(18px);
      color: #ffffff;
      font-weight: 800;
    }

    vaadin-notification-card::part(content) {
      background-color: transparent;
    }

    vaadin-multi-select-combo-box-overlay::part(overlay) {
      color: white;
      background: #181b30;
    }
  `,
];

export { vaadinStyles };
