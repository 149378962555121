import { css } from 'lit';

const scrollbarStyles = [
  css`
    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      border: 4px solid gray;
      background-clip: padding-box;
      border-radius: 25px;
      height: 50px;
    }
  `,
];

export { scrollbarStyles };
