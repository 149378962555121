import { css } from 'lit';

const AdeleShellStyles = [
  css`
    vaadin-side-nav::part(children) {
      height: 100%;
    }

    vaadin-side-nav::part(children),
    .nav__top,
    .nav__bottom {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    }

    .nav__top {
      flex-grow: 1;
    }

    .nav-user-details {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      width: 227px;
    }

    .client-options {
      display: flex;
      padding: 18px 12px;
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: nowrap;
      background-color: rgba(28, 31, 40, 0.25);
      border-radius: 22px;
      position: relative;
      backdrop-filter: blur(8px);
    }

    @media (max-width: 1800px) {
      .nav__bottom {
        padding-bottom: 2rem;
      }
    }
  `,
];

export { AdeleShellStyles };
