import { connect } from 'pwa-helpers/connect-mixin';

import { configureStore } from '@reduxjs/toolkit';
import { appConfigReducer } from './slices/appConfig';
import { userSettingsReducer } from './slices/userSettings';
import { userReducer } from './slices/user';
import { tokenReducer } from './slices/token';
import { projectListReducer } from './slices/projectList';
import { userQueryReducer } from './slices/userQuery';
import { threadReducer } from './slices/thread';
import { chatModelsReducer } from './slices/chatModels';
import { claimsReducer } from './slices/claims';

const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    // TODO: Remove unused parts of this code
    appConfig: appConfigReducer,
    userSettings: userSettingsReducer,
    user: userReducer,
    token: tokenReducer,
    projectList: projectListReducer,

    // Adele
    userQuery: userQueryReducer,
    threadQuery: threadReducer,
    chatModels: chatModelsReducer,
    claims: claimsReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
type AppDispatch = typeof store.dispatch;

export { RootState, AppDispatch, store, connect };
