import { MondayParams, MondayQueries } from './types';

const submitFeedback = ({
  rating,
  text,
  date,
  question,
  answer,
  id,
}: MondayParams) => `
  mutation rating2 {
    create_item (
      board_id: 6075018411,
      item_name: "topics",
      column_values: \"{\\\"rating\\\" : {\\\"rating\\\" : ${rating}},\\\"date4\\\": \\\"${date}\\\",\\\"text\\\": \\\"${text}\\\",\\\"text_1\\\": \\\"${question}\\\",\\\"text_12\\\": \\\"${answer ?? ''}\\\",\\\"numbers\\\": ${id}}\"
    ) {
      id
      name
      column_values {
        id
        text
      }
    }
  }
`;

const submitHelpRequest = ({ text, userName, date }: MondayParams) => {
  const columnValues = JSON.stringify({
    text__1: text,
    text_1__1: userName,
    project_owner: {
      changed_at: '2024-05-22T11:10:49.574Z',
      personsAndTeams: [
        { id: 30717377, kind: 'person' },
        { id: 38011828, kind: 'person' },
        { id: 27789212, kind: 'person' },
      ],
    },
    project_status: {
      index: 5,
      post_id: null,
      changed_at: date,
    },
    project_priority: {
      index: 5,
      post_id: null,
      changed_at: '2024-05-22T11:11:18.715Z',
    },
  });

  return `
    mutation createHelpRequest {
      create_item (
        board_id: 6687139668,
        item_name: "ADELe Support Request",
        column_values: "${columnValues.replace(/"/g, '\\"')}"
      ) {
        id
        name
        column_values {
          id
          text
        }
      }
    }
  `;
};


const mondayQueries: MondayQueries = {
  submitFeedback,
  submitHelpRequest,
};

export { mondayQueries, MondayQueries };
