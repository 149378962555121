import { authFunctionsClient } from '../functions/client';
import {
  setAccounts,
  setAgreedToTerms,
  setAllowedAgents,
  setAllowedSouces,
  setAppConfig,
  setAppConfigCanUpload,
  setAppConfigIsAdmin,
  setAppConfigProducts,
  setAppConfigVanilla,
  setCurrentAccount,
  setDefaultSource,
} from '../../state/slices/appConfig';
import { setClaims } from '../../state/slices/claims';
import { store } from '../../state/store';
import { ClaimsProductType } from './types/ClaimsProductType';

const setClaimsAccountsToStore = claimsAccounts => {
  store.dispatch(
    setAccounts(
      claimsAccounts.map(account => ({
        id: account.id,
        name: account.name,
      }))
    )
  );
};

const getClaimsAccountsFromServer = async () => {
  const result = await authFunctionsClient.getClaimsAccounts();

  return result;
};

const getClaimsById = async id => {
  const result = await authFunctionsClient.getClaims(id);

  return result;
};

const getClaims = async claims => {
  const result = (await Promise.allSettled(
    claims.map(account => authFunctionsClient.getClaims(account.id))
  )) as { status: 'fulfilled' | 'rejected'; value: any }[];

  const mappedClaimsResult = result
    .filter(res => res.status === 'fulfilled')
    .map(r => r.value);

  return mappedClaimsResult;
};

const saveClaimsToStore = claims => {
  store.dispatch(setClaims(claims));
};

const setCurrentAccountToStore = account => {
  store.dispatch(
    setCurrentAccount({
      id: account.id,
      name: account.name,
    })
  );
};

const getDefaultAccountFromClaims = async claimsAccounts => {
  setClaimsAccountsToStore(claimsAccounts);

  const claims = await getClaims(claimsAccounts);

  saveClaimsToStore(claims);

  // Retrieve accounts products
  const claimsProducts: ClaimsProductType = claims.reduce((acc, curr) => {
    const { products } = curr;

    // eslint-disable-next-line no-param-reassign
    acc = {
      ...acc,
      [curr.accounts]: products,
    };

    return acc;
  }, []);
  // Find the first account with access to "ADELe"
  const accountsWithAccess = Object.entries(claimsProducts).reduce(
    (acc: Array<string>, [key, value]) => {
      if (
        acc.length === 0 &&
        value.find(product => product.name.toUpperCase() === 'ADELE')
      ) {
        acc.push(key);
      }

      return acc;
    },
    []
  );

  const defaultAccount = claims.find(
    acc => acc.accounts === accountsWithAccess[0]
  );

  if (defaultAccount != null) {
    setCurrentAccountToStore(
      claimsAccounts.find(acc => acc.id.toString() === defaultAccount.accounts)
    );
  }

  return defaultAccount;
};

const setDataToStore = data => {
  store.dispatch(
    setAppConfig({
      hasuraToken: data.tokens.hasuraToken,
      cubeToken: data.tokens.cubeToken,
      searchToken: data.tokens.searchToken,
      chatToken: data.tokens.chatToken,
      products: data.products,
      accounts: data.accounts,
      user: data.user_id,
    })
  );
  store.dispatch(setDefaultSource(data?.default_source || false));
  store.dispatch(setAllowedSouces(data?.allowed_sources || false));
  store.dispatch(setAllowedAgents(data?.allowed_agents || false));
  store.dispatch(setAgreedToTerms(data?.agreed_to_terms || false));
  store.dispatch(setAppConfigCanUpload(data?.can_upload || false));
  store.dispatch(setAppConfigIsAdmin(data?.is_admin || false));
  store.dispatch(setAppConfigProducts(data?.products || []));
  store.dispatch(setAppConfigVanilla(data?.vanilla || false));
};

export {
  getClaimsAccountsFromServer,
  getDefaultAccountFromClaims,
  getClaimsById,
  setDataToStore,
};
