import { property } from 'lit/decorators';
import { classMap } from 'lit-html/directives/class-map';
import {
  NNBase,
  css,
  html,
  nothing,
} from '@mch/nn-web-viz/dist/packages/base/Base';
import {
  registerStyles,
  css as vaadinCss,
} from '@vaadin/vaadin-themable-mixin';

import { connect, store } from '../../../state/store';
import {
  setSelectedSource,
  setselectedAgent,
} from '../../../state/slices/appConfig';

import '@vaadin/tooltip';
import { scrollbarStyles } from '../../../assets/styles/scrollbar';
import { vaadinStyles } from '../../../assets/styles/vaadinStyles';

const getAgentIcon = (agent: string) => {
  switch (agent) {
    case 'Compliance':
      return 'https://assets.codepen.io/7773162/gavel-regular+1.png';

    case 'Message Optimizer':
      return 'https://assets.codepen.io/7773162/message-bot-regular+1.png';

    case 'Diversion & Inclusion':
      return 'https://assets.codepen.io/7773162/user-group-simple-regular+1.png';

    case 'Competitive Assessment':
      return 'https://assets.codepen.io/7773162/chart-pie-sharp-regular+1.png';

    case 'Sales Rep Buddy':
      return 'https://assets.codepen.io/7773162/person-regular+1.png';

    default:
      return 'https://assets.codepen.io/7773162/person-regular+1.png';
  }
};

const getSourcesIcon = (source: string) => {
  switch (source) {
    case 'Market Research':
      return 'https://assets.codepen.io/7773162/magnifying-glass-chart-regular+%281%29+1.png';

    case 'FDA.gov':
      return 'https://assets.codepen.io/7773162/fda+1.png';

    case 'pubmed.gov':
      return 'https://assets.codepen.io/7773162/pubmed-logo-white+%282%29+1+%281%29.png';

    case 'Congresses':
      return 'https://assets.codepen.io/7773162/people-group-regular+%281%29+1.png';

    case 'Clinical Studies':
      return 'https://assets.codepen.io/7773162/memo-sharp-regular+1.png';

    case 'Internet':
      return 'https://assets.codepen.io/7773162/Frame+569+%281%29.png';

    case 'ASCO':
      return 'https://assets.codepen.io/7773162/Frame+569.png';

    case 'Pitch GPT':
      return 'https://assets.codepen.io/7773162/memo-sharp-regular+1.png';

    default:
      return 'https://assets.codepen.io/7773162/Frame+569+%281%29.png';
  }
};

interface User {
  id: number;
  name: string;
}

registerStyles('vaadin-tooltip-overlay', [
  vaadinCss`
     [part='overlay'] {
        color: white;
        background-color: rgba(28, 31, 40);
      }
    `,
]);

const renderInstructions = () => html`
  <div class="instructions">
    Click the
    <vaadin-button slot="suffix" theme="tertiary-inline icon">
      <vaadin-icon icon="vaadin:info-circle"></vaadin-icon>
    </vaadin-button>
    for details on each source
  </div>
`;

class AdeleSourceAgents extends connect(store)(NNBase) {
  @property({ type: Number, attribute: 'selected-source' }) selectedSource:
    | number
    | null = null;

  @property({ type: Number, attribute: 'selected-agent' }) selectedAgent:
    | number
    | null = null;

  @property({ type: String, attribute: 'display' }) display: string = 'sources';

  @property({ type: Object }) currentAccount: User | null = null;

  @property({ type: Array }) sources: Array<any> = [];

  @property({ type: Array }) agents: Array<any> = [];

  @property({ type: Number }) _openedTooltip: number | null = null;

  static styles: any = [
    ...scrollbarStyles,
    ...vaadinStyles,
    css`
      :host {
        box-sizing: border-box;
        width: 539px;
        height: 419px;
        background-color: rgba(28, 31, 40);
        border-radius: 20px 20px 0 0;
        position: var(--source-agents-position, absolute);
        right: var(--source-agents-absolute-right, 100px);
        bottom: var(--source-agents-absolute-bottom, 100px);
        color: #ffffff;
      }

      :host::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(14px) grayscale(0.75) saturate(0);
        border-radius: 20px 20px 0 0;
      }
      .container {
        width: 539px;
        height: 419px;
        border-radius: 20px 20px 0 0;
        position: var(--source-agents-position, absolute);
        right: 0;
        bottom: 0;
        color: #ffffff;
      }

      .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 1;
        margin: 1.5rem 2rem;
      }

      .instructions {
        font-size: 0.85rem;
      }

      .header-item {
        text-transform: capitalize;
        height: 13px;
        flex-shrink: 0;
        font-family: Nunito Sans, var(--default-font-family);
        font-size: 16px;
        font-weight: 800;
        line-height: 13px;
        position: relative;
        text-align: left;
        white-space: nowrap;
        z-index: 1;
      }

      .options-container {
        display: flex;
        width: 476px;
        height: 326px;
        justify-content: space-between;
        position: relative;
        z-index: 53;
        margin: 16px 0 0 24px;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: none;
      }

      /* Sources - START */
      .sources-container {
        display: flex;
        width: 232px;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        flex-shrink: 0;
        flex-wrap: nowrap;
        position: relative;
        z-index: 2;
      }

      .container::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(14px);
        border-radius: 16px 16px 0 0;
      }

      .agents-container {
        display: flex;
        width: 232px;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        flex-shrink: 0;
        flex-wrap: nowrap;
        position: relative;
        z-index: 2;
      }

      .item__container {
        padding: 8px;
        display: flex;
        min-width: 0;
        gap: 8px;
        align-items: center;
        align-self: stretch;
        flex-shrink: 0;
        flex-wrap: nowrap;
        border-radius: 10px;
        position: relative;
        z-index: 3;
        cursor: pointer;
      }

      .item__container:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      .item-icon__container {
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        z-index: 4;
      }

      .icon__wrapper {
        width: 24px;
        height: 24px;
        position: relative;
        overflow: hidden;
        z-index: 5;
        margin: 12px 0 0 12px;
      }

      .icon {
        width: 23.996px;
        height: 24.002px;
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: relative;
        z-index: 6;
        margin: 0 0 0 0;
      }

      .label__wrapper {
        display: flex;
        gap: 10px;
        align-items: center;
        flex-shrink: 0;
        flex-wrap: nowrap;
        position: relative;
        z-index: 7;
        cursor: pointer;
      }

      .label {
        display: flex;
        width: 100px;
        justify-content: flex-start;
        align-items: flex-start;
        flex-shrink: 0;
        flex-basis: auto;
        font-family: Nunito Sans, var(--default-font-family);
        font-size: 14px;
        font-weight: 600;
        line-height: 13px;
        color: #ffffff;
        position: relative;
        text-align: left;
        white-space: break-spaces;
        z-index: 8;
      }

      .indicator__wrapper {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        flex-direction: row-reverse;
      }

      .active-indicator {
        display: none;
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        background-color: #30d158;
        border-radius: 100px;
        position: relative;
        z-index: 18;
      }

      .active-indicator.active {
        display: block;
      }

      .separator {
        min-width: 0;
        height: 0.5px;
        align-self: stretch;
        flex-shrink: 0;
        background-color: rgba(178, 178, 178, 0.066);
        position: relative;
        z-index: 11;
      }
    `,
  ];

  updated(changedProps) {
    super.update(changedProps);

    if (changedProps.has('selectedSource')) {
      store.dispatch(setSelectedSource(this.selectedSource));
    }

    if (changedProps.has('selectedAgent')) {
      store.dispatch(setselectedAgent(this.selectedAgent));
    }
  }

  stateChanged(_state): void {
    this.sources = _state.appConfig.allowedSources.map(source => ({
      id: source.id,
      label: source.name,
      backgroundImage: getSourcesIcon(source.name),
      description: source.description,
    }));
    this.agents = _state.appConfig.allowedAgents.map(agent => ({
      id: agent.id,
      label: agent.name,
      backgroundImage: getAgentIcon(agent.name),
      description: agent.description,
    }));

    if (this.currentAccount?.id !== _state.appConfig.currentAccount?.id) {
      this.currentAccount = _state.appConfig.currentAccount;
      this.selectedSource = null;
      this.selectedAgent = null;
    }
  }

  _toggleSelectedItem(id: number) {
    if (this.display === 'sources') {
      this.selectedSource = this.selectedSource !== id ? id : -1;
    } else {
      this.selectedAgent = this.selectedAgent !== id ? id : -1;
    }
  }

  _renderHeaderItems() {
    return html`<span class="header-item">${this.display}</span>`;
  }

  _renderColumn(items) {
    if (items.length === 0) {
      return nothing;
    }

    const selectedItem =
      this.display === 'sources' ? this.selectedSource : this.selectedAgent;

    return items.map(
      (item, index) => html`
        <div
          class="item__container"
          id=${item.label}
          @click=${() => this._toggleSelectedItem(item.id)}
          @keydown=${() => this._toggleSelectedItem(item.id)}
        >
          <div class="item-icon__container">
            <div class="icon__wrapper">
              <div
                class="icon"
                style="background-image: url(${item.backgroundImage});"
              ></div>
            </div>
          </div>
          <div class="label__wrapper">
            <span class="label">${item.label}</span>
            <div class="indicator__wrapper">
              <div
                class="active-indicator ${classMap({
                  active: selectedItem === item.id,
                })}"
              ></div>
              <vaadin-button
                slot="suffix"
                theme="tertiary-inline icon"
                @click="${e => {
                  e.stopImmediatePropagation();

                  this._openedTooltip =
                    this._openedTooltip === item.id ? null : item.id;
                }}"
              >
                <vaadin-icon icon="vaadin:info-circle"></vaadin-icon>
              </vaadin-button>
            </div>
          </div>
        </div>
        <vaadin-tooltip
          for="${item.label}"
          text="${item?.description ||
          'Description for this source is coming soon.'}"
          manual
          position="top-end"
          .opened=${item.id === this._openedTooltip}
        >
        </vaadin-tooltip>
        ${this.sources.length - 1 !== index
          ? html`<div class="separator"></div>`
          : nothing}
      `
    );
  }

  render() {
    const items = this.display === 'sources' ? this.sources : this.agents;
    if (items?.length === 0) return nothing;

    const middleIndex = Math.ceil(items.length / 2);

    const firstPart = items.slice(0, middleIndex);
    const secondPart = items.slice(middleIndex);

    return html`
      <div class="container">
        <div class="header-container">
          ${this._renderHeaderItems()}${renderInstructions()}
        </div>
        <div class="options-container">
          <div class="sources-container">${this._renderColumn(firstPart)}</div>
          <div class="agents-container">${this._renderColumn(secondPart)}</div>
        </div>
      </div>
    `;
  }
}

export { AdeleSourceAgents };
