import { axios, Axios } from '@bundled-es-modules/axios';
import { AUTH_FUNCTIONS_ENDPOINT } from '../../constants';
import { getaccessToken } from './utils';

class AuthFunctionsClient {
  private client: Axios | null = null;

  public accessToken: string | null | undefined = null;

  public initComplete: Promise<void>;

  constructor() {
    this.initComplete = this._setAxiosInstance();
  }

  async _setAxiosInstance() {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    this.accessToken = await getaccessToken();
    this.client = axios.create({
      baseURL: AUTH_FUNCTIONS_ENDPOINT,
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        ContentType: 'application/json',
      },
    });
  }

  getClaims(id) {
    return this.getWithHeaders('/claims', id);
  }

  getClaimsAccounts() {
    return this.get('/claims-accounts');
  }

  getAdminData(id) {
    return this.getWithHeaders('/admin-data', id);
  }

  saveAdminData(payload, id) {
    return this.postWithHeaders('/admin-data', payload, id);
  }

  async getWithHeaders(path, id) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    this.client.defaults.headers.common['selected-account-id'] = id;
    this.client.defaults.headers.common['protocol-version'] = '2';
    const response = await this.client.get(path);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }

  async get(path) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    const response = await this.client.get(path);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }

  async postWithHeaders(path, body, id) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    this.client.defaults.headers.common['selected-account-id'] = id;
    this.client.defaults.headers.common['protocol-version'] = '2';
    const response = await this.client.post(path, body);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }

  async post(path, body) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    const response = await this.client.post(path, body);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }
}
export { AuthFunctionsClient };
