const routePrefix = '/app';
const storageKeys = {
  HASURA_TOKEN: 'nn-hasura-token',
  CUBE_TOKEN: 'nn-cube-token',
  SEARCH_TOKEN: 'nn-search-token',
};

const JSON_STRINGIFIED_PREFIX = 'nn-json-stringified-';

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

function camelCaseToSpace(str) {
  // Insert a space before any uppercase letter preceded by a lowercase letter
  return str.replace(/([a-z])([A-Z])/g, '$1 $2');
}

const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

function getQueryParams() {
  return new URLSearchParams(window.location.search);
}

const removeFromLocalStorage = key => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error);
    return undefined;
  }
};

const saveToLocalStorage = (key, value, deleteValueOnFail = false) => {
  let valueToStore = value;
  if (!key) {
    return;
  }
  if (typeof valueToStore === 'object' && valueToStore != null) {
    valueToStore = `${JSON_STRINGIFIED_PREFIX}${JSON.stringify(value)}`;
  }
  try {
    localStorage.setItem(key, valueToStore);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error);
    if (deleteValueOnFail) {
      removeFromLocalStorage(key);
    }
  }
};

const loadFromLocalStorage = key => {
  let value;
  if (!key) {
    return undefined;
  }
  try {
    value = localStorage.getItem(key);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error);
    return undefined;
  }
  if (value != null && value.startsWith(JSON_STRINGIFIED_PREFIX)) {
    const rawValue = value.split(JSON_STRINGIFIED_PREFIX)[1];
    if (rawValue != null) {
      value = JSON.parse(rawValue);
    }
  }
  return value;
};

const loadingAwareLabel = (label, loading) => {
  if (typeof label !== 'string') {
    return '';
  }

  return loading ? `Loading ${label.toLowerCase()} ...` : label;
};

const getToken = async () => {
  const { store } = await import('./state/store');

  return store.getState().token.value?.accessToken;
};

// C is for cookie, terms and conditions
// Cookie utility functions
export function getCookie(name: string): string | null {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? decodeURIComponent(match[2]) : null;
}

export function setCookie(name: string, value: string, days = 365): void {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${encodeURIComponent(
    value
  )};expires=${expires.toUTCString()};path=/`;
}

export function removeMarkdown(markdown) {
  return (
    markdown
      // Replace headers
      .replace(/(^|\s)(#{1,6})\s+([^\n]+)/g, '$1$3')
      // Replace bold and italic
      .replace(/(\*\*|__)(.*?)\1/g, '$2')
      .replace(/(\*|_)(.*?)\1/g, '$2')
      // Replace inline code
      .replace(/`(.*?)`/g, '$1')
      // Replace links
      .replace(/\[([^\]]+)\]\([^\)]+\)/g, '$1')
      // Replace images
      .replace(/!\[([^\]]*)\]\([^\)]+\)/g, '$1')
      // Replace blockquotes
      .replace(/^\s{0,3}>\s?/gm, '')
      // Replace unordered lists
      .replace(/^\s*[-+*]\s+/gm, '')
      // Replace ordered lists
      .replace(/^\s*\d+\.\s+/gm, '')
      // Replace horizontal rules
      .replace(/^\s*[-\*_]{3,}\s*$/gm, '')
      // Replace new lines
      .replace(/\n{2,}/g, '\n\n')
      // Replace remaining markdown characters
      .replace(/([*_~])/g, '')
      .replace(/<sup>.*?<\/sup>/g, '')
  );
}

export {
  routePrefix,
  storageKeys,
  loadFromLocalStorage,
  saveToLocalStorage,
  delay,
  groupBy,
  getQueryParams,
  loadingAwareLabel,
  getToken,
};
