import {
  CollectionParams,
  HasuraQueries,
  ThreadCollectionParams,
  ThreadParams,
} from './types';

const createCollection = ({ name, description }: CollectionParams) => `
  mutation InsertCollectionMutation {
    insert_collection(objects: {description: "${description}", name: "${name}"}) {
      returning {
        created
        description
        name
        id
        updated
        user_id
      }
    }
  }
`;

const getCollections = () => `
  query MyQuery {
    collection {
      created
      description
      id
      name
      updated
      user_id
      thread_collections {
        thread {
          name
          created
          history
          id
          updated
          user_id
        }
      }
    }
  }
`;

const getCollectionById = ({ id }: CollectionParams) => `
  query MyQuery {
    collection(where: {id: {_eq: "${id}"}}) {
      id
      created
      description
      name
      updated
      user_id
      thread_collections {
        thread {
          name
          created
          history
          id
          updated
          user_id
        }
      }
    }
  }
`;

const createThreadId = name => `
  mutation CreateThreadIdMutation {
  insert_thread(objects: {name: ${JSON.stringify(name)}}) {
    returning {
      created
      history
      id
      name
      updated
      user_id
    }
  }
}
`;

const createThread = () => `
  mutation CreateThreadMutation($objects: [message_insert_input!] = {}) {
    insert_message(objects: $objects) {
      returning {
        thread_id
        source_id
        agent_id
        id
        answer
        citations
        created
        image_url
        question
        updated
        recommendations
        tooltip
        step
        attachment
      }
    }
  }
`;

const insertMessageToThread = () => `
  mutation AppendThreadMutation($objects: [message_insert_input!] = {}) {
    insert_message(objects: $objects) {
      returning {
        thread_id
        source_id
        agent_id
        id
        answer
        citations
        created
        image_url
        question
        updated
        recommendations
        tooltip
        step
        attachment
      }
    }
  }
`;

const getThreads = () => `
  query MyQuery {
    thread {
      created
      id
      name
      updated
      user_id
      messages(limit: 1, order_by: {created: asc}) {
        question
        answer
      }
    }
  }
`;

const getThreadByThreadId = ({ id }: ThreadParams) => `
  query MyQuery {
    message(where: {thread_id: {_eq: ${id}}}) {
      answer
      citations
      created
      id
      image_url
      question
      recommendations
      tooltip
      step
      attachment
      thread_id
      source_id
      agent_id
      updated
    }
  }
`;

const addThreadToCollection = ({
  collectionId,
  threadId,
}: ThreadCollectionParams) => `
  mutation MyMutation {
    insert_thread_collection(objects: {collection_id: ${collectionId}, thread_id: ${threadId}}, on_conflict: {constraint: thread_collection_pkey}) {
      affected_rows
    }
  }
`;

const updateThreadHistory = ({ id }: ThreadParams) => `
  mutation MyMutation($history: json = "") {
    update_thread(where: {id: {_eq: ${id}}}, _set: {history: $history}) {
      returning {
        history
        created
        id
        name
        updated
        user_id
      }
    }
  }
`;

const updateTerms = ({
  id,
  agreedToTerms,
}: {
  id: number;
  agreedToTerms: boolean;
}) => `
  mutation UpdateTermsMutation{
    update_user(where: {id: {_eq: "${id}"}}, _set: {agreed_to_terms: ${agreedToTerms}}) {
      affected_rows
    }
  }
`;

const hasuraQueries: HasuraQueries = {
  createCollection,
  getCollections,
  getCollectionById,
  createThread,
  insertMessageToThread,
  getThreads,
  getThreadByThreadId,
  addThreadToCollection,
  updateThreadHistory,
  updateTerms,
  createThreadId,
};

export { hasuraQueries, HasuraQueries };
