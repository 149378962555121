import { css } from 'lit';

const AdeleHelpOverlayStyles = [
  css`
    :host {
      width: 800px;
      height: auto;
      background: rgba(28, 31, 40, 0.28);
      box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
      backdrop-filter: blur(18px);
      color: #fff;
      box-sizing: border-box;
      border-radius: 15px;
      margin-left: 10%;
    }

    .header-actions {
      display: flex;
      justify-content: flex-end;
      padding: 2rem 2rem 0 0;
    }

    nn-icon {
      cursor: pointer;
    }

    vaadin-text-field {
      color: white;
    }

    .content {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 1rem 3rem 3rem;
    }

    /* Button */
    .search-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 10px;
      margin-top: 4rem;
    }

    .search {
      margin: auto;
      width: 100%;
      border-radius: 30px;
      border: 0;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-size: 24px;
    }

    .search__input {
      outline: transparent;
      border: 0;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }

    .search__input {
      border-radius: 8px;
      background: #ffffff20;
      padding-left: 15px;
      padding-right: 150px;
      width: 100%;
      height: 156px;
      font-size: 1.2rem;
      color: #fff;
      resize: none;
    }

    .search__input::placeholder {
      color: #fff;
      opacity: 60%;
    }

    .search__input-wrap {
      position: relative;
    }

    .search__label {
      position: absolute;
      width: 1px;
      height: 1px;
    }

    .search__send {
      display: inline-block;
      cursor: pointer;
    }

    .search__actions {
      justify-content: space-between;
      align-items: end;
      width: 30px;
      display: flex;
      position: absolute;
      right: 20px;
      bottom: 10px;
      height: 100%;
      background-color: transparent;
      -webkit-tap-highlight-color: transparent;
    }

    .search button {
      background-color: transparent;
      border: 0;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }

    .search__input-wrap {
      display: flex;
      align-items: center;
      position: relative;
      box-sizing: border-box;
      background-clip: padding-box;
      z-index: 1;
      border: solid 5px transparent;
      border-radius: 8px;
      width: 100%;
      height: auto;
    }

    h3 {
      margin: 0 0 0.5rem;
    }

    .input__label {
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
      margin-bottom: 1rem;
    }

    .submit__wrapper {
      display: flex;
      justify-content: flex-end;
    }

    .submit__btn {
      margin-top: 2rem;
    }

    nn-star-rating {
      margin-bottom: 40px;
    }
    a {
      color: #fff;
    }
  `,
];

export { AdeleHelpOverlayStyles };
