import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Token } from '../../types';

// Define a type for the slice state
interface TokenState {
  value: Token | undefined;
}

// Define the initial state using that type
const initialState: TokenState = {
  value: undefined,
};

const tokenSlice = createSlice({
  name: 'token',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setaccessToken: (state, action: PayloadAction<string>) => {
      state.value = {
        ...state.value,
        accessToken: action.payload,
      };
    },
  },
});

const { setaccessToken } = tokenSlice.actions;
const tokenReducer = tokenSlice.reducer;

export { TokenState, tokenReducer, setaccessToken };
