import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Project, ProjectList } from '../../types';

// Define a type for the slice state
interface ProjectListState {
  value: Array<Project> | undefined | any;
}

// Define the initial state using that type
const initialState: ProjectListState = {
  value: undefined,
};

const projectListSlice = createSlice({
  name: 'projectList',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addProject: (state, action: PayloadAction<Project>) => {
      state.value = [action.payload, ...state.value];
    },
    setProjectList: (state, action: PayloadAction<Array<Project>>) => {
      state.value = action.payload.sort(
        (a, b) => new Date(b.created).valueOf() - new Date(a.created).valueOf()
      );
    },
  },
});

const { setProjectList, addProject } = projectListSlice.actions;
const projectListReducer = projectListSlice.reducer;

export { ProjectListState, projectListReducer, setProjectList, addProject };
