import { hasuraClient, HasuraClient } from "./client";
import { hasuraQueries } from "./queries";

class HasuraDataProvider {
  private _client: HasuraClient;

  constructor(client = hasuraClient) {
    this._client = client;
  }

  async query(query, variables = {}) {
    const result = await this._client.load(query, variables);
    return result;
  }
}

const hasura = new HasuraDataProvider();

export { hasura, hasuraQueries };